<template>
  <div>
    <!-- Header -->
    <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
      <!-- <div class="container">
        <div class="header-body text-center mb-7">
          <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-8 px-5">
              <h1 class="text-white">Welcome!</h1>
              <p class="text-lead text-white">Use these awesome forms to login or create new account in your project for free.</p>
            </div>
          </div>
        </div>
      </div> -->

      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
          xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container mt--8 pb-5">
      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-6 col-md-8">
          <div class="card bg-secondary border-0 mb-0">
            <div class="card-header bg-transparent pt-5 pb-6">
              <loading-spinner v-if="loading"> </loading-spinner>
              <div v-if="error">
                <div class="mb-2">
                  <base-alert type="warning" :text="error">{{ error }}
                    <base-button type="default" block class="mt-3" v-if="error.code === 'auth/wrong-password'"
                      @click="passwordReset">
                      <i class="ni ni-send mr-2"></i>Recover Password
                    </base-button>
                    <!--
                      <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
      </button>
-->
                  </base-alert>
                </div>
              </div>

              <div class="text-muted text-center mt-2 mb-3">
                <small>Sign in with</small>
              </div>
              <div class="btn-wrapper text-center row">
                <div class="col-12 mb-3">
                  <base-button type="secondary" block @click="onSignInMicrosoft"><i
                      class="fab fa-microsoft mr-2"></i>ECB Microsoft Account</base-button>
                </div>
                <div class="col-12">
                  <base-button type="secondary" block @click="onSignInGoogle"><i class="fab fa-google mr-2"></i>Google
                    Account</base-button>
                </div>
                <!-- <div class="col-12">
                    <base-button type="secondary" block @click="passwordLogin=!passwordLogin">Echo username/password</base-button>
                                    </div> -->
              </div>


            </div>

            <div class="card-body pb-5 pt-5">
              <div class="text-center text-muted pb-3">
                <small>Or sign in with your Echo Account</small>
              </div>

              <form role="form">
                <base-input alternative class="mb-3" prepend-icon="ni ni-email-83" placeholder="Email" v-model="email">
                </base-input>

                <base-input alternative prepend-icon="ni ni-lock-circle-open" type="password" placeholder="Password"
                  v-model="password">
                </base-input>
                <a class="text-primary cursor" @click="resetPasswordModal = true">Reset Password</a>
                <modal :show.sync="resetPasswordModal" modal-classes="modal-scrollable">
                  <form @submit.prevent="reset">
                    <base-input alternative class="mb-3" prepend-icon="ni ni-email-83" placeholder="Email"
                      v-model="email">
                    </base-input>
<base-button :disabled="!email.length>0" @click="passwordReset">Send Reset Email</base-button>
                  </form>
                </modal>

                <div class="text-center">
                  <base-button type="primary" class="mt-1" @click="onSignInUP">Sign in</base-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      resetPasswordModal: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    error() {
      return this.$store.getters["user/error"];
    },
    loading() {
      return this.$store.getters["user/loading"];
    },
    entry() {
      return this.$store.getters["user/entry"];
    },
  },
  watch: {
    user(value) {
      if (value !== null && value !== undefined) {
        this.$router.push(this.entry);
      }
    },
  },
  methods: {
    onSignInGoogle() {
      this.$store.dispatch("user/signUserInGoogle");
    },
    onSignInMicrosoft() {
      this.$store.dispatch("user/signUserInMicrosoft");
    },
    onSignInUP() {
      this.$store.dispatch("user/signUserIn", {
        email: this.email,
        password: this.password,
      });
    },
    onDismissed() {
      this.$store.dispatch("user/clearError");
    },
    passwordReset() {
      this.$store.dispatch("user/clearError");
      this.$store.dispatch("user/passwordReset", this.email);
      this.resetPasswordModal = false
    },
  },
};
</script>
<style scoped>
.ecbLogo {
  height: 120px;
  padding: 10px;
}

.box {
  width: 170px;
}

.cursor{
  cursor: pointer;
}
</style>
